<template>
  <v-container fluid style="height: 100%" class="px-8">
    <v-progress-linear v-if="isLoading" color="primary" indeterminate class="progress full-width"></v-progress-linear>

    <div style="width: inherit">
      <v-toolbar height="50" class="my-2" elevation="4" rounded>
        <v-btn text class="nav-button text-capitalize" @click="goBack">
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>
      </v-toolbar>
    </div>
    <v-banner :class="{ 'half-width': !isMobile }">
      <h4 class="text-h4">Create Special Invoice</h4>
      <v-label>Create Special Invoice</v-label>
    </v-banner>

    <div :class="{ 'half-width': !isMobile }">
      <v-card>
        <v-card-text class="fill-height">
          <v-row class="form-wrapper">
            <v-col cols="12" md="3" class="overflow-y-auto fill-height" v-if="!isMobile">
              <v-stepper v-model="currentStep" vertical style="box-shadow: unset">
                <v-stepper-step v-for="step in steps" :complete="step.complete" :step="step.id" :key="step.id">
                  {{ step.name }}
                </v-stepper-step>
                <v-stepper-content :step="4"></v-stepper-content>
              </v-stepper>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" md="9" class="form-content">
              <v-stepper v-model="currentStep" style="box-shadow: unset">
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-row>
                      <v-col cols="6" md="6">
                        <span class="red--text">
                          <small>*Required</small>
                        </span>
                        <v-select
                          :menu-props="{ bottom: true, offsetY: true }"
                          ref="tripTypeId"
                          v-model="$v.form.tripTypeId.$model"
                          :items="filteredTripTypes"
                          item-text="name"
                          item-value="id"
                          label="Trip Type"
                          :rules="[() => !!form.tripTypeId || 'This field is required']"
                          required
                          outlined
                          @change="handleTripTypeSelect"
                        >
                        </v-select>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6" md="6">
                        <span class="red--text">
                          <small>*Required</small>
                        </span>
                        <v-select
                          :menu-props="{ bottom: true, offsetY: true }"
                          ref="tripEventIds"
                          v-model="$v.form.tripEventIds.$model"
                          :items="filteredTripEvents"
                          item-text="name"
                          item-value="id"
                          label="Event"
                          multiple
                          v-on:input="limiter"
                          :rules="[() => form.tripEventIds.length > 0 || 'This field is required']"
                          required
                          outlined
                          :readonly="!form.tripTypeId"
                        >
                          <template
                            v-slot:item="{ item }"
                            v-if="form.tripTypeId && tripTypesById[form.tripTypeId].allowEventRates"
                          >
                            {{ item.name }}
                          </template>
                        </v-select>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <span class="red--text">
                          <small>*Required</small>
                        </span>
                        <v-select
                          :menu-props="{ bottom: true, offsetY: true }"
                          ref="locationId"
                          v-model="$v.form.locationId.$model"
                          :items="locations"
                          :item-text="getLocationText"
                          item-value="id"
                          label="Your School/Dept"
                          :rules="[() => !!form.locationId || 'This field is required']"
                          required
                          outlined
                          @change="handleLocationSelect"
                        >
                        </v-select>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="12" md="6">
                        <div class="fill-height d-flex align-center total-mileage">
                          <h3>Total Mileage:</h3>
                          <v-progress-circular size="30" color="primary" indeterminate v-if="loadingRoute" />
                          <span v-if="geoCodeError"> {{ geoCodeError }}</span>
                          <span v-else-if="totalDistance"> {{ totalDistance }} miles</span>
                        </div>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <span class="red--text">
                          <small>*Required</small>
                        </span>
                        <destination-autocomplete
                          :destinationFilters="tripRequestConfig.display.newDestination ? {} : { prospects: 0 }"
                          :hint="tripRequestConfig.labels.commonDestinations"
                          :loading="loadingDestinations"
                          :includePlaces="tripRequestConfig.display.newDestination"
                          :rules="[() => !!form.destinationId || 'This field is required']"
                          :semesterId="2"
                          @change="handleDestination"
                          @destinationChanged="handleNewDestination"
                          @internalLoading="(val) => (loadingDestinations = val)"
                          label="Main Destination"
                          outlined
                          persistent-hint
                          ref="destinationId"
                          required
                          v-model="$v.form.destinationId.$model"
                        >
                          <template #item="{ on, attrs, item }">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-icon color="orange" left v-if="item.place_id">mdi-map-marker-plus</v-icon>
                              <v-icon color="green" left v-else>mdi-map-marker</v-icon>
                              <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item>
                          </template>

                          <template #append-item>
                            <v-list-item
                              @click="createNewDestination()"
                              v-if="tripRequestConfig.display.newDestination"
                            >
                              <v-list-item-title
                                >Having trouble finding an address? Click here create a new
                                destination</v-list-item-title
                              >
                            </v-list-item>
                          </template>
                        </destination-autocomplete>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <v-row>
                      <v-col cols="6" md="6">
                        <span class="red--text">
                          <small>*Required</small>
                        </span>
                        <date-picker
                          :rules="[() => !!form.leaveDate || 'This field is required']"
                          label="Leave Date"
                          ref="leaveDate"
                          @input="handleLeaveDate($event)"
                          required
                          v-model="$v.form.leaveDate.$model"
                        ></date-picker>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6" md="6">
                        <span class="red--text">
                          <small>*Required</small>
                        </span>
                        <time-picker
                          :rules="[() => !!form.leaveTime || 'This field is required']"
                          label="Leave Time"
                          ref="leaveTime"
                          @input="dateTimeRuleCheck"
                          required
                          v-model="$v.form.leaveTime.$model"
                        ></time-picker>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                      <v-col cols="6" md="6">
                        <span class="red--text">
                          <small>*Required</small>
                        </span>
                        <date-picker
                          :rules="[() => !!form.returnDate || 'This field is required']"
                          label="Return Date"
                          ref="returnDate"
                          @input="dateTimeRuleCheck"
                          required
                          v-model="$v.form.returnDate.$model"
                        ></date-picker>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6" md="6">
                        <span class="red--text">
                          <small>*Required</small>
                        </span>
                        <time-picker
                          :rules="[() => !!form.returnTime || 'This field is required']"
                          label="Return Time"
                          ref="returnTime"
                          @input="dateTimeRuleCheck"
                          required
                          v-model="$v.form.returnTime.$model"
                        ></time-picker>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row v-if="dateError">
                      <v-col cols="12">
                        <v-alert outlined type="error" text>{{ dateError }}</v-alert>
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <v-row>
                      <v-col cols="6" md="6">
                        <v-select
                          :menu-props="{ bottom: true, offsetY: true }"
                          ref="vehicleId"
                          v-model="$v.form.vehicleId.$model"
                          :items="vehicles"
                          item-text="name"
                          item-value="id"
                          label="Vehicle"
                          :rules="[() => !!form.vehicleId || 'This field is required']"
                          required
                          outlined
                        >
                        </v-select>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6" md="6">
                        <v-text-field
                          ref="distance"
                          v-model="$v.form.distance.$model"
                          type="number"
                          label="Distance"
                          :rules="[() => !!form.distance || 'This field is required']"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                      <v-col cols="6" md="6">
                        <v-select
                          :menu-props="{ bottom: true, offsetY: true }"
                          ref="driverId"
                          v-model="$v.form.driverId.$model"
                          :items="drivers"
                          item-text="fullName"
                          item-value="id"
                          label="Driver"
                          :rules="[() => !!form.driverId || 'This field is required']"
                          required
                          outlined
                        >
                        </v-select>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6" md="6">
                        <v-text-field
                          ref="driverEmail"
                          :value="$v.form.driverId.$model ? driversById[$v.form.driverId.$model].email : ''"
                          label="Driver Email"
                          required
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                      <v-col cols="6" md="6">
                        <v-select
                          :menu-props="{ bottom: true, offsetY: true }"
                          ref="assistant"
                          v-model="$v.form.assistantId.$model"
                          :items="drivers"
                          item-text="fullName"
                          item-value="id"
                          label="Assistant"
                          :rules="[() => !!form.assistantId || 'This field is required']"
                          required
                          outlined
                        >
                        </v-select>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6" md="6">
                        <v-text-field
                          ref="assistantEmail"
                          :value="$v.form.assistantId.$model ? driversById[$v.form.assistantId.$model].email : ''"
                          label="Assistant Email"
                          required
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-toolbar class="footer-border-top rounded-r-0" flat>
          <v-spacer></v-spacer>

          <template>
            <v-btn class="mx-2" color="primary" v-show="currentStep > 1" @click="stepClick(currentStep - 1)">
              Prev
            </v-btn>
            <v-btn class="mx-2" color="primary" v-show="currentStep < 3" @click="stepClick(currentStep + 1)">
              Next
            </v-btn>
          </template>

          <div v-if="currentStep === 3">
            <v-btn class="mx-2" color="primary" @click="save" :disabled="isLoading"> Submit </v-btn>
          </div>
        </v-toolbar>
      </v-card>
    </div>

    <new-destination
      :destination="destination"
      @newDestinationCreated="setNewDestinationId"
      prevent-autocomplete
      ref="newDestination"
    ></new-destination>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePicker';
import DestinationAutocomplete from '@/components/DestinationAutocomplete.vue';
import NewDestination from '@/views/TripRequestForm/NewDestination.vue';
import { inputProps, getMiles } from '@/util';

export default {
  name: 'SpecialInvoice',
  mixins: [validationMixin],
  components: {
    DatePicker,
    TimePicker,
    DestinationAutocomplete,
    NewDestination,
  },
  data() {
    return {
      inputProps,
      isLoading: false,
      steps: [
        {
          id: 1,
          name: 'General',
          complete: false,
        },
        {
          id: 2,
          name: 'Dates',
          complete: false,
        },
        {
          id: 3,
          name: 'Driver and Vehicles',
          complete: false,
        },
      ],
      currentStep: 1,
      destination: {},
      loadingDestinations: false,
      loadingRoute: false,
      totalDistance: 0,
      geoCodeError: null,
      dateError: null,
      form: {
        leaveDate: null,
        leaveTime: null,
        returnDate: null,
        returnTime: null,
        tripTypeId: null,
        tripEventIds: [],
        locationId: null,
        destinationId: null,
        vehicleId: null,
        distance: null,
        driverId: null,
        assistantId: null,
      },
    };
  },
  validations() {
    return {
      form: {
        leaveDate: { required },
        leaveTime: { required },
        returnDate: { required },
        returnTime: { required },
        tripTypeId: { required },
        tripEventIds: { required },
        locationId: { required },
        destinationId: { required },
        vehicleId: { required },
        distance: {},
        driverId: {},
        assistantId: {},
      },
    };
  },
  computed: {
    ...mapGetters('app', ['isMobile']),
    ...mapGetters('user', ['me']),
    ...mapGetters('config', ['invoiceConfig', 'tripRequestConfig']),
    ...mapGetters('invoice', ['invoiceColumns', 'statusConfig', 'invoiceFilters']),
    ...mapGetters('destination', ['destinations', 'prospectiveDestinations']),
    ...mapGetters('driver', ['drivers', 'driversById']),
    ...mapGetters('location', ['schools', 'locationsById']),
    ...mapGetters('stop', ['stops']),
    ...mapGetters('tripType', ['allTripTypes', 'tripTypesById']),
    ...mapGetters('tripEvent', ['tripEvents', 'tripEventsById']),
    ...mapGetters('vehicle', ['vehicles', 'vehicleById']),
    filteredTripTypes() {
      return this.allTripTypes.filter((e) => !e.categoryId && e.active);
    },
    filteredTripEvents() {
      return this.tripEvents.filter((e) => e.tripTypeId == this.form.tripTypeId);
    },
    locations() {
      const locations = this.schools;
      locations.sort((a, b) => {
        return a[this.tripRequestConfig.other.locationSort].localeCompare(
          b[this.tripRequestConfig.other.locationSort],
          undefined,
          { numeric: this.tripRequestConfig.other.locationSort == 'code' }
        );
      });
      return locations;
    },
  },
  methods: {
    ...mapActions('geo', ['route']),
    ...mapActions('vehicle', ['getAvailableVehicles']),
    ...mapActions('invoice', ['createSpecialInvoice']),
    dateTimeRuleCheck() {
      const leaveDate = this.$v.form.leaveDate.$model;
      const leaveTime = this.$v.form.leaveTime.$model;
      const returnDate = this.$v.form.returnDate.$model;
      const returnTime = this.$v.form.returnTime.$model;

      let dateError = '';
      if (leaveDate && returnDate) {
        if (returnDate < leaveDate) {
          dateError = 'Return date cannot be before leave date';
        } else if (
          returnDate == leaveDate &&
          returnTime &&
          leaveTime.localeCompare(returnTime, undefined, {
            ignorePunctuation: true,
            numeric: true,
          }) > 0
        ) {
          dateError = 'Return time cannot be before leave time';
        } else if (
          returnDate == leaveDate &&
          returnTime &&
          leaveTime.localeCompare(returnTime, undefined, {
            ignorePunctuation: true,
            numeric: true,
          }) == 0
        ) {
          dateError = 'Return date time cannot be the same as leave date time';
        }
      }
      this.dateError = dateError;
    },
    handleTripTypeSelect() {
      this.$v.form.tripEventIds.$model = [];
    },
    handleLeaveDate(date) {
      this.$v.form.returnDate.$model = date;
      this.dateTimeRuleCheck();
    },
    async handleLocationSelect() {
      await this.checkTotalMileage();
    },
    async handleDestination() {
      await this.checkTotalMileage();
    },
    handleNewDestination(value) {
      if (!value || !value.place_id) return;

      this.destination = { ...value, name: '' };
      this.$refs.newDestination.dialog = true;
    },
    async setNewDestinationId(id) {
      if (!id) return;

      this.$v.form.destinationId.$model = id;
      this.$nextTick(() => {
        this.$refs.destinationId.blur();
        this.checkTotalMileage();
      });
    },
    createNewDestination() {
      this.destination = { name: '', address: {} };
      this.$refs.newDestination.dialog = true;
      this.$refs.destinationId.blur();
    },
    async checkTotalMileage() {
      const locationId = this.$v.form.locationId.$model;
      const destinationId = this.$v.form.destinationId.$model;
      if (!locationId || !destinationId) return;

      try {
        this.loadingRoute = true;
        const locStop = this.stops.find((e) => e.schoolId == this.locationsById[locationId].schoolId);
        let destStop = this.destinations.find((e) => e.id == destinationId);
        if (!destStop) destStop = this.prospectiveDestinations.find((e) => e.id == destinationId);

        const [op, rp] = await Promise.all([
          this.route({ points: [locStop.address, destStop.address], options: { details: true } }),
          this.route({ points: [destStop.address, locStop.address], options: { details: true } }),
        ]);
        this.totalDistance = getMiles(op.distance + rp.distance);
      } catch (error) {
        if (error.errcode === '676A71B6') {
          this.geoCodeError =
            'One or more of the provided stops are missing coordinates. Directions and trip itinerary estimations could not be calculated';
        } else if (error.errcode === '676A71B7') {
          this.geoCodeError = 'Estimations and directions failed to calculate';
        } else {
          this.geoCodeError = 'Unable to calculate total mileage';
        }
      } finally {
        this.loadingRoute = false;
      }
    },
    limiter(e) {
      if (this.form.tripTypeId && this.tripTypesById[this.form.tripTypeId].allowEventRates) {
        if (e.length > 1) this.form.tripEventIds = [e[e.length - 1]];
        this.$refs.tripEventIds.blur();
      }
    },
    getLocationText(location) {
      return location.name + ' (' + location.code + ')';
    },
    stepClick(step) {
      // only add validation for next
      if (step > this.currentStep) {
        const form = this.$v.form;
        let requiredFields = [];
        switch (this.currentStep) {
          case 1:
            requiredFields = ['tripTypeId', 'tripEventIds', 'locationId', 'destinationId'];
            break;
          case 2:
            requiredFields = ['leaveDate', 'leaveTime', 'returnDate', 'returnTime'];
            break;
        }

        requiredFields.forEach((field) => this.$refs[field].validate(true));
        const hasError = requiredFields.some((field) => form[field].$invalid);
        if (hasError || this.dateError) {
          this.$myalert.error('Please complete this section before moving on');
          return;
        }
      }
      this.currentStep = step;
    },
    async save() {
      this.isLoading = true;
      try {
        const result = await this.createSpecialInvoice(this.form);
        if (result) {
          this.$myalert.success('Special Invoice saved successfully');
          this.$router.push('/invoices');
        }
      } catch (e) {
        this.$myalert.error(`Unable to save special Invoice, message: ${e.message}`);
      } finally {
        this.isLoading = false;
      }
    },
    goBack() {
      this.$router.push('/invoices');
    },
  },
};
</script>

<style scoped>
.form-wrapper {
  height: calc(100dvh - 84px - 94px - 160px);
  height: calc(100vh - 84px - 94px - 160px);
}

.full-width {
  max-width: 100%;
}

.half-width {
  width: 50%;
}

.form-content {
  overflow-y: auto;
  height: 100%;
}

.total-mileage {
  vertical-align: middle;
}
</style>
