var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "px-8",
      staticStyle: { height: "100%" },
      attrs: { fluid: "" },
    },
    [
      _vm.isLoading
        ? _c("v-progress-linear", {
            staticClass: "progress full-width",
            attrs: { color: "primary", indeterminate: "" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { width: "inherit" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "my-2",
              attrs: { height: "50", elevation: "4", rounded: "" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "nav-button text-capitalize",
                  attrs: { text: "" },
                  on: { click: _vm.goBack },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-arrow-left"),
                  ]),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-banner",
        { class: { "half-width": !_vm.isMobile } },
        [
          _c("h4", { staticClass: "text-h4" }, [
            _vm._v("Create Special Invoice"),
          ]),
          _c("v-label", [_vm._v("Create Special Invoice")]),
        ],
        1
      ),
      _c(
        "div",
        { class: { "half-width": !_vm.isMobile } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "fill-height" },
                [
                  _c(
                    "v-row",
                    { staticClass: "form-wrapper" },
                    [
                      !_vm.isMobile
                        ? _c(
                            "v-col",
                            {
                              staticClass: "overflow-y-auto fill-height",
                              attrs: { cols: "12", md: "3" },
                            },
                            [
                              _c(
                                "v-stepper",
                                {
                                  staticStyle: { "box-shadow": "unset" },
                                  attrs: { vertical: "" },
                                  model: {
                                    value: _vm.currentStep,
                                    callback: function ($$v) {
                                      _vm.currentStep = $$v
                                    },
                                    expression: "currentStep",
                                  },
                                },
                                [
                                  _vm._l(_vm.steps, function (step) {
                                    return _c(
                                      "v-stepper-step",
                                      {
                                        key: step.id,
                                        attrs: {
                                          complete: step.complete,
                                          step: step.id,
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(step.name) + " ")]
                                    )
                                  }),
                                  _c("v-stepper-content", {
                                    attrs: { step: 4 },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider", { attrs: { vertical: "" } }),
                      _c(
                        "v-col",
                        {
                          staticClass: "form-content",
                          attrs: { cols: "12", md: "9" },
                        },
                        [
                          _c(
                            "v-stepper",
                            {
                              staticStyle: { "box-shadow": "unset" },
                              model: {
                                value: _vm.currentStep,
                                callback: function ($$v) {
                                  _vm.currentStep = $$v
                                },
                                expression: "currentStep",
                              },
                            },
                            [
                              _c(
                                "v-stepper-items",
                                [
                                  _c(
                                    "v-stepper-content",
                                    { attrs: { step: "1" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", md: "6" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [
                                                  _c("small", [
                                                    _vm._v("*Required"),
                                                  ]),
                                                ]
                                              ),
                                              _c("v-select", {
                                                ref: "tripTypeId",
                                                attrs: {
                                                  "menu-props": {
                                                    bottom: true,
                                                    offsetY: true,
                                                  },
                                                  items: _vm.filteredTripTypes,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  label: "Trip Type",
                                                  rules: [
                                                    () =>
                                                      !!_vm.form.tripTypeId ||
                                                      "This field is required",
                                                  ],
                                                  required: "",
                                                  outlined: "",
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleTripTypeSelect,
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.tripTypeId
                                                      .$model,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.tripTypeId,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.tripTypeId.$model",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", md: "6" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [
                                                  _c("small", [
                                                    _vm._v("*Required"),
                                                  ]),
                                                ]
                                              ),
                                              _c("v-select", {
                                                ref: "tripEventIds",
                                                attrs: {
                                                  "menu-props": {
                                                    bottom: true,
                                                    offsetY: true,
                                                  },
                                                  items: _vm.filteredTripEvents,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  label: "Event",
                                                  multiple: "",
                                                  rules: [
                                                    () =>
                                                      _vm.form.tripEventIds
                                                        .length > 0 ||
                                                      "This field is required",
                                                  ],
                                                  required: "",
                                                  outlined: "",
                                                  readonly:
                                                    !_vm.form.tripTypeId,
                                                },
                                                on: { input: _vm.limiter },
                                                scopedSlots: _vm._u(
                                                  [
                                                    _vm.form.tripTypeId &&
                                                    _vm.tripTypesById[
                                                      _vm.form.tripTypeId
                                                    ].allowEventRates
                                                      ? {
                                                          key: "item",
                                                          fn: function ({
                                                            item,
                                                          }) {
                                                            return [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          },
                                                        }
                                                      : null,
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.$v.form.tripEventIds
                                                      .$model,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.tripEventIds,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.tripEventIds.$model",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [
                                                  _c("small", [
                                                    _vm._v("*Required"),
                                                  ]),
                                                ]
                                              ),
                                              _c("v-select", {
                                                ref: "locationId",
                                                attrs: {
                                                  "menu-props": {
                                                    bottom: true,
                                                    offsetY: true,
                                                  },
                                                  items: _vm.locations,
                                                  "item-text":
                                                    _vm.getLocationText,
                                                  "item-value": "id",
                                                  label: "Your School/Dept",
                                                  rules: [
                                                    () =>
                                                      !!_vm.form.locationId ||
                                                      "This field is required",
                                                  ],
                                                  required: "",
                                                  outlined: "",
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleLocationSelect,
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.locationId
                                                      .$model,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.locationId,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.locationId.$model",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fill-height d-flex align-center total-mileage",
                                                },
                                                [
                                                  _c("h3", [
                                                    _vm._v("Total Mileage:"),
                                                  ]),
                                                  _vm.loadingRoute
                                                    ? _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            size: "30",
                                                            color: "primary",
                                                            indeterminate: "",
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  _vm.geoCodeError
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.geoCodeError
                                                            )
                                                        ),
                                                      ])
                                                    : _vm.totalDistance
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.totalDistance
                                                            ) +
                                                            " miles"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c("v-spacer"),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [
                                                  _c("small", [
                                                    _vm._v("*Required"),
                                                  ]),
                                                ]
                                              ),
                                              _c("destination-autocomplete", {
                                                ref: "destinationId",
                                                attrs: {
                                                  destinationFilters: _vm
                                                    .tripRequestConfig.display
                                                    .newDestination
                                                    ? {}
                                                    : { prospects: 0 },
                                                  hint: _vm.tripRequestConfig
                                                    .labels.commonDestinations,
                                                  loading:
                                                    _vm.loadingDestinations,
                                                  includePlaces:
                                                    _vm.tripRequestConfig
                                                      .display.newDestination,
                                                  rules: [
                                                    () =>
                                                      !!_vm.form
                                                        .destinationId ||
                                                      "This field is required",
                                                  ],
                                                  semesterId: 2,
                                                  label: "Main Destination",
                                                  outlined: "",
                                                  "persistent-hint": "",
                                                  required: "",
                                                },
                                                on: {
                                                  change: _vm.handleDestination,
                                                  destinationChanged:
                                                    _vm.handleNewDestination,
                                                  internalLoading: (val) =>
                                                    (_vm.loadingDestinations =
                                                      val),
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                      item,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-list-item",
                                                          _vm._g(
                                                            _vm._b(
                                                              {},
                                                              "v-list-item",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            item.place_id
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "orange",
                                                                      left: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-map-marker-plus"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "green",
                                                                      left: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-map-marker"
                                                                    ),
                                                                  ]
                                                                ),
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.text
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "append-item",
                                                    fn: function () {
                                                      return [
                                                        _vm.tripRequestConfig
                                                          .display
                                                          .newDestination
                                                          ? _c(
                                                              "v-list-item",
                                                              {
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.createNewDestination()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      "Having trouble finding an address? Click here create a new destination"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ]),
                                                model: {
                                                  value:
                                                    _vm.$v.form.destinationId
                                                      .$model,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.destinationId,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.destinationId.$model",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-stepper-content",
                                    { attrs: { step: "2" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", md: "6" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [
                                                  _c("small", [
                                                    _vm._v("*Required"),
                                                  ]),
                                                ]
                                              ),
                                              _c("date-picker", {
                                                ref: "leaveDate",
                                                attrs: {
                                                  rules: [
                                                    () =>
                                                      !!_vm.form.leaveDate ||
                                                      "This field is required",
                                                  ],
                                                  label: "Leave Date",
                                                  required: "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.handleLeaveDate(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.leaveDate
                                                      .$model,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.leaveDate,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.leaveDate.$model",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", md: "6" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [
                                                  _c("small", [
                                                    _vm._v("*Required"),
                                                  ]),
                                                ]
                                              ),
                                              _c("time-picker", {
                                                ref: "leaveTime",
                                                attrs: {
                                                  rules: [
                                                    () =>
                                                      !!_vm.form.leaveTime ||
                                                      "This field is required",
                                                  ],
                                                  label: "Leave Time",
                                                  required: "",
                                                },
                                                on: {
                                                  input: _vm.dateTimeRuleCheck,
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.leaveTime
                                                      .$model,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.leaveTime,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.leaveTime.$model",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", md: "6" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [
                                                  _c("small", [
                                                    _vm._v("*Required"),
                                                  ]),
                                                ]
                                              ),
                                              _c("date-picker", {
                                                ref: "returnDate",
                                                attrs: {
                                                  rules: [
                                                    () =>
                                                      !!_vm.form.returnDate ||
                                                      "This field is required",
                                                  ],
                                                  label: "Return Date",
                                                  required: "",
                                                },
                                                on: {
                                                  input: _vm.dateTimeRuleCheck,
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.returnDate
                                                      .$model,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.returnDate,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.returnDate.$model",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", md: "6" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [
                                                  _c("small", [
                                                    _vm._v("*Required"),
                                                  ]),
                                                ]
                                              ),
                                              _c("time-picker", {
                                                ref: "returnTime",
                                                attrs: {
                                                  rules: [
                                                    () =>
                                                      !!_vm.form.returnTime ||
                                                      "This field is required",
                                                  ],
                                                  label: "Return Time",
                                                  required: "",
                                                },
                                                on: {
                                                  input: _vm.dateTimeRuleCheck,
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.returnTime
                                                      .$model,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.returnTime,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.returnTime.$model",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                        ],
                                        1
                                      ),
                                      _vm.dateError
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      attrs: {
                                                        outlined: "",
                                                        type: "error",
                                                        text: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.dateError)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-stepper-content",
                                    { attrs: { step: "3" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", md: "6" } },
                                            [
                                              _c("v-select", {
                                                ref: "vehicleId",
                                                attrs: {
                                                  "menu-props": {
                                                    bottom: true,
                                                    offsetY: true,
                                                  },
                                                  items: _vm.vehicles,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  label: "Vehicle",
                                                  rules: [
                                                    () =>
                                                      !!_vm.form.vehicleId ||
                                                      "This field is required",
                                                  ],
                                                  required: "",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.vehicleId
                                                      .$model,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.vehicleId,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.vehicleId.$model",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                ref: "distance",
                                                attrs: {
                                                  type: "number",
                                                  label: "Distance",
                                                  rules: [
                                                    () =>
                                                      !!_vm.form.distance ||
                                                      "This field is required",
                                                  ],
                                                  required: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.distance.$model,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.distance,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.distance.$model",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", md: "6" } },
                                            [
                                              _c("v-select", {
                                                ref: "driverId",
                                                attrs: {
                                                  "menu-props": {
                                                    bottom: true,
                                                    offsetY: true,
                                                  },
                                                  items: _vm.drivers,
                                                  "item-text": "fullName",
                                                  "item-value": "id",
                                                  label: "Driver",
                                                  rules: [
                                                    () =>
                                                      !!_vm.form.driverId ||
                                                      "This field is required",
                                                  ],
                                                  required: "",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.driverId.$model,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.driverId,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.driverId.$model",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                ref: "driverEmail",
                                                attrs: {
                                                  value: _vm.$v.form.driverId
                                                    .$model
                                                    ? _vm.driversById[
                                                        _vm.$v.form.driverId
                                                          .$model
                                                      ].email
                                                    : "",
                                                  label: "Driver Email",
                                                  required: "",
                                                  readonly: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", md: "6" } },
                                            [
                                              _c("v-select", {
                                                ref: "assistant",
                                                attrs: {
                                                  "menu-props": {
                                                    bottom: true,
                                                    offsetY: true,
                                                  },
                                                  items: _vm.drivers,
                                                  "item-text": "fullName",
                                                  "item-value": "id",
                                                  label: "Assistant",
                                                  rules: [
                                                    () =>
                                                      !!_vm.form.assistantId ||
                                                      "This field is required",
                                                  ],
                                                  required: "",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.assistantId
                                                      .$model,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.assistantId,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.assistantId.$model",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                ref: "assistantEmail",
                                                attrs: {
                                                  value: _vm.$v.form.assistantId
                                                    .$model
                                                    ? _vm.driversById[
                                                        _vm.$v.form.assistantId
                                                          .$model
                                                      ].email
                                                    : "",
                                                  label: "Assistant Email",
                                                  required: "",
                                                  readonly: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-toolbar",
                {
                  staticClass: "footer-border-top rounded-r-0",
                  attrs: { flat: "" },
                },
                [
                  _c("v-spacer"),
                  [
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentStep > 1,
                            expression: "currentStep > 1",
                          },
                        ],
                        staticClass: "mx-2",
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.stepClick(_vm.currentStep - 1)
                          },
                        },
                      },
                      [_vm._v(" Prev ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentStep < 3,
                            expression: "currentStep < 3",
                          },
                        ],
                        staticClass: "mx-2",
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.stepClick(_vm.currentStep + 1)
                          },
                        },
                      },
                      [_vm._v(" Next ")]
                    ),
                  ],
                  _vm.currentStep === 3
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                color: "primary",
                                disabled: _vm.isLoading,
                              },
                              on: { click: _vm.save },
                            },
                            [_vm._v(" Submit ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("new-destination", {
        ref: "newDestination",
        attrs: { destination: _vm.destination, "prevent-autocomplete": "" },
        on: { newDestinationCreated: _vm.setNewDestinationId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }